<template>
  <v-col
    v-if="permisos.includes('dns_emails:list')
      && cards.includes('dns')
      && dnsEmpty"
    cols="6"
  >
    <DNS
      :user="user"
    />
  </v-col>
</template>

<script>
import { mapState } from 'vuex'
import DNS from './notificaciones/DNS.vue'

export default {
  components: {
    DNS,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
      cards: localStorage.getItem('cards_auth'),
    }
  },
  computed: {
    ...mapState({
      cardsDashboard: state => state.app.cardsDashboard,
      dnsEmpty: state => state.app.dnsEmpty,
    }),
  },
}
</script>
