<template>
  <fragment
    v-if="(
      permisos.includes('pvp:list') && cardsDashboard.includes('ventas-totales') && salesMonth.amountAll > 0
    )
      ||
      (
        permisos.includes('pvp:list') && cardsDashboard.includes('ventas-contrate') && salesMonth.amountHotels > 0
      )
      ||
      (
        permisos.includes('pvp:list') && cardsDashboard.includes('ventas-contrate') && salesMonth.amountCars > 0
      )
      ||
      salesMonthPreview.name
      ||
      salesWeek.name
    "
  >
    <template>
      <v-col
        v-if="(permisos.includes('pvp:list') || permisos.includes('*')) && cards.includes('ventas-totales') && salesMonth.amountAll > 0"
        cols="6"
      >
        <VentasMes
          :user="user"
        />
      </v-col>
      <v-col
        v-if="(permisos.includes('pvp:list') || permisos.includes('*')) && cards.includes('ventas-contrate') && salesMonth.amountHotels > 0"
        cols="6"
      >
        <VentasMesByContrateHotel
          :user="user"
        />
      </v-col>
      <v-col
        v-if="(permisos.includes('pvp:list') || permisos.includes('*')) && cards.includes('ventas-contrate') && salesMonth.amountCars > 0"
        cols="6"
      >
        <VentasMesByContrateCar
          :user="user"
        />
      </v-col>
      <v-col
        v-if="salesMonthPreview.name"
        cols="6"
      >
        <template>
          <VendedorDestacado
            :user="user"
          />
        </template>
      </v-col>
      <v-col
        v-if="salesWeek.name"
        cols="12"
      >
        <template>
          <VendedorVentasSemanal
            :user="user"
          />
        </template>
      </v-col>
    </template>

    <template v-if="isLoadingSales">
      <v-col
        v-for="i in 2"
        :key="i"
        cols="6"
      >
        <v-skeleton-loader
          type="table-heading, list-item-two-line, table-tfoot, actions"
        ></v-skeleton-loader>
      </v-col>
    </template>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import VentasMes from './sales/VentasMes.vue'
import VentasMesByContrateHotel from './sales/VentasMesByContrateHotel.vue'
import VentasMesByContrateCar from './sales/VentasMesByContrateCar.vue'
import VendedorDestacado from './sales/VendedorDestacado.vue'
import VendedorVentasSemanal from './sales/VendedorVentasSemanal.vue'

export default {
  components: {
    VentasMes,
    VentasMesByContrateHotel,
    VentasMesByContrateCar,
    VendedorDestacado,
    VendedorVentasSemanal,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
      isLoadingSales: false,
      sales: {},
      salesByContrate: [],
      cards: localStorage.getItem('cards_auth'),
    }
  },
  computed: {
    ...mapState({
      cardsDashboard: state => state.app.cardsDashboard,
      salesMonthAll: state => state.app.salesMonthAll,
      salesMonth: state => state.app.salesMonth,
      salesMonthPreview: state => state.app.salesMonthPreview,
      salesMonthPreviews: state => state.app.salesMonthPreviews,
      salesWeeks: state => state.app.salesWeeks,
      salesWeek: state => state.app.salesWeek,
    }),
  },
  created() {
    this.getSales()
    this.getSalesWeekPreview()
    this.getSalesMonthPreview()
  },
  methods: {
    ...mapMutations([
      'setSalesMonth',
      'setSalesMonthPreview',
      'setSalesWeek',
    ]),
    getSales() {
      if (this.salesMonthAll.length > 0) {
        const userId = localStorage.getItem('user_id')
        let findd = false
        this.salesMonthAll.forEach(element => {
          if (findd === false) {
            if (element.users.includes(userId)) {
              this.setSalesMonth(element)
              findd = true
            }
          }
        })
      }
    },
    getSalesWeekPreview() {
      if (this.salesWeeks.length > 0) {
        const userId = localStorage.getItem('user_id')
        let findd = false
        this.salesWeeks.forEach(element => {
          if (findd === false) {
            if (element.user_id.includes(userId)) {
              this.setSalesWeek(element)
              findd = true
            }
          }
        })
      }
    },
    getSalesMonthPreview() {
      if (this.salesMonthPreviews.name !== null) {
        const userId = localStorage.getItem('user_id')
        if (this.salesMonthPreviews.user_id === userId) {
          this.setSalesMonthPreview(this.salesMonthPreviews)
        }
      }
    },

    /*
    getSales() {
      if (localStorage.getItem('salesMonth')) {
        const userId = localStorage.getItem('user_id')
        const saless = JSON.parse(localStorage.getItem('salesMonth'))
        let findd = false
        saless.forEach(element => {
          if (findd === false) {
            if (element.users.includes(userId)) {
              this.sales = element
              findd = true
            }
          }
        })
        this.isLoadingSales = false
      } else {
        this.isLoadingSales = false
      }
    },
    async getSales() {
      await this.axios
        .post('sales/sales-mounth', { users: 'all' }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) { } else {
            this.sales = res.data
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoadingSales = false
        })
    },
    async getSalesByContrate() {
      await this.axios
        .post('sales/sales-mounth-by-contrate', { users: 'all' }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          console.log(res.data)
          // eslint-disable-next-line no-empty
          if (res.data.success === false) { } else {
            this.sales = res.data
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoadingSales = false
        })
    }, */
  },
}
</script>
