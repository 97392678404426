<!-- eslint-disable vue/no-v-html -->
<template>
  <fragment
    v-if="(
      !activeDeepL && cards.includes('api-deepl-manager')
    ) || (
      activeDeepL && cards.includes('api-deepl-count')
    )"
  >
    <v-col
      v-if="!activeDeepL && cards.includes('api-deepl-manager')"
      cols="12"
    >
      <DeepLOff />
    </v-col>
    <v-col
      v-if="activeDeepL && cards.includes('api-deepl-count')"
      cols="6"
    >
      <DeepLCount />
    </v-col>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'
import DeepLOff from './DeepLOff.vue'
import DeepLCount from './DeepLCount.vue'

export default {
  components: {
    DeepLOff,
    DeepLCount,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      cards: localStorage.getItem('cards_auth'),
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/img/translate/translate1.svg'),
      imgs: [],
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
    }
  },
  computed: {
    ...mapState({
      cardsDashboard: state => state.app.cardsDashboard,
      activeDeepL: state => state.app.activeDeepL,
    }),
  },
}
</script>
