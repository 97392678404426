var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((
    _vm.permisos.includes('pvp:list') && _vm.cardsDashboard.includes('ventas-totales') && _vm.salesMonth.amountAll > 0
  )
    ||
    (
      _vm.permisos.includes('pvp:list') && _vm.cardsDashboard.includes('ventas-contrate') && _vm.salesMonth.amountHotels > 0
    )
    ||
    (
      _vm.permisos.includes('pvp:list') && _vm.cardsDashboard.includes('ventas-contrate') && _vm.salesMonth.amountCars > 0
    )
    ||
    _vm.salesMonthPreview.name
    ||
    _vm.salesWeek.name
  )?_c('fragment',[[((_vm.permisos.includes('pvp:list') || _vm.permisos.includes('*')) && _vm.cards.includes('ventas-totales') && _vm.salesMonth.amountAll > 0)?_c('v-col',{attrs:{"cols":"6"}},[_c('VentasMes',{attrs:{"user":_vm.user}})],1):_vm._e(),((_vm.permisos.includes('pvp:list') || _vm.permisos.includes('*')) && _vm.cards.includes('ventas-contrate') && _vm.salesMonth.amountHotels > 0)?_c('v-col',{attrs:{"cols":"6"}},[_c('VentasMesByContrateHotel',{attrs:{"user":_vm.user}})],1):_vm._e(),((_vm.permisos.includes('pvp:list') || _vm.permisos.includes('*')) && _vm.cards.includes('ventas-contrate') && _vm.salesMonth.amountCars > 0)?_c('v-col',{attrs:{"cols":"6"}},[_c('VentasMesByContrateCar',{attrs:{"user":_vm.user}})],1):_vm._e(),(_vm.salesMonthPreview.name)?_c('v-col',{attrs:{"cols":"6"}},[[_c('VendedorDestacado',{attrs:{"user":_vm.user}})]],2):_vm._e(),(_vm.salesWeek.name)?_c('v-col',{attrs:{"cols":"12"}},[[_c('VendedorVentasSemanal',{attrs:{"user":_vm.user}})]],2):_vm._e()],(_vm.isLoadingSales)?_vm._l((2),function(i){return _c('v-col',{key:i,attrs:{"cols":"6"}},[_c('v-skeleton-loader',{attrs:{"type":"table-heading, list-item-two-line, table-tfoot, actions"}})],1)}):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }