<template>
  <v-col
    v-if="cards.includes('markups') && showCardMarkups"
    cols="6"
  >
    <Markups
      :user="user"
    />
  </v-col>
</template>

<script>
import { mapState } from 'vuex'
import Markups from './markups/Markups.vue'

export default {
  components: {
    Markups,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
      cards: localStorage.getItem('cards_auth'),
    }
  },
  computed: {
    ...mapState({
      cardsDashboard: state => state.app.cardsDashboard,
      showCardMarkups: state => state.app.showCardMarkups,
    }),
  },
}
</script>
