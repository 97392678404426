<template>
  <fragment>
    <template v-if="permisos.includes('contrate_hotels:edit') || permisos.includes('*')">
      <template v-if="cards.includes('vencimiento-contrates')">
        <!--order="1"
        class="align-self-top"-->
        <v-col
          v-if="contratesPendientsHotelsUser.product === 'hotels'"
          cols="6"
        >
          <VencimientoHotelsAll
            :user="user"
          />
        </v-col>
        <!--<v-col
          v-for="(contrate, index) in contratesHotels"
          :key="index"
          cols="6"
          order="1"
          class="align-self-end"
        >
          <VencimientoHotels
            :user="user"
            :contrate="contrate"
          />
        </v-col> -->
      </template>
      <template v-if="isLoadingContratesHotels">
        <v-col
          v-for="i in 2"
          :key="i"
          cols="6"
        >
          <v-skeleton-loader
            type="table-heading, list-item-two-line, table-tfoot, actions"
          ></v-skeleton-loader>
        </v-col>
      </template>
    </template>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import VencimientoHotels from './contrates/VencimientoHotels.vue'
import VencimientoHotelsAll from './contrates/VencimientoHotelsAll.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VencimientoHotels,
    VencimientoHotelsAll,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
      cards: localStorage.getItem('cards_auth'),
      isLoadingContratesHotels: false,
      contratesHotels: [],
      contratesHotelsAll: [],
    }
  },
  computed: {
    ...mapState({
      cardsDashboard: state => state.app.cardsDashboard,
      contratesPendientsHotelsAll: state => state.app.contratesPendientsHotelsAll,
      contratesPendientsHotelsUser: state => state.app.contratesPendientsHotelsUser,
    }),
  },
  created() {
    this.getContratesHotels()
  },
  methods: {
    ...mapMutations([
      'setContratesPendientsHotelsUser',
    ]),
    getContratesHotels() {
      if (this.contratesPendientsHotelsAll.length > 0) {
        const userId = localStorage.getItem('user_id')
        let findd = false
        this.contratesPendientsHotelsAll.forEach(element => {
          if (findd === false) {
            if (element.users.includes(userId)) {
              this.setContratesPendientsHotelsUser(element)
              findd = true
            }
          }
        })
      }
    },

    /* async getContratesHotels() {
      await this.axios
        .post('contrate_hotels/pendients', { users: 'all' }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.contratesHotelsAll = res.data
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoadingContratesHotels = false
        })
    }, */
  },
}
</script>
