<template>
  <v-row
    v-if="!isLoadingPage"
  >
    <template v-if="!isLoading">
      <CintilloCreateUser
        v-if="cards.includes('cintillo-create-users') && showCardCreateUser"
        :user="user"
      />

      <!--ARREGLAR-->
      <Contrates
        v-if="cards.includes('vencimiento-contrates')"
        :user="user"
      />

      <!--ARREGLAR-->
      <Sales
        v-if="cards.includes('ventas-totales') || cards.includes('ventas-contrate')"
        :user="user"
      />
      <ChatBot
        v-if="cards.includes('chatBot') && chatBotRead"
        :user="user"
      />
      <Notificaciones
        v-if="cards.includes('dns')"
        :user="user"
      />

      <CintilloCreateSeller
        v-if="cards.includes('cintillo-create-users') && showCardCreateSeller && !showCardCreateUser"
        :user="user"
      />

      <CintilloCreateAgente
        v-if="cards.includes('cintillo-create-users') && showCardCreateAgent && !showCardCreateUser && !showCardCreateSeller"
        :user="user"
      />

      <CintilloCreateAdmin
        v-if="cards.includes('cintillo-create-users') && showCardCreateUserAdmin && !showCardCreateUser && !showCardCreateSeller"
        :user="user"
      />

      <Markups
        v-if="cards.includes('markups')"
        :user="user"
      />

      <!--ARREGLAR-->
      <DataGeneral
        v-if="cards.includes('data-general')"
        :user="user"
      />

      <DeepL
        v-if="cards.includes('api-deepl-manager') || cards.includes('api-deepl-count')"
        :user="user"
      />

      <!--
      <Grafana
        v-if="user.nivel <= 0"
        :user="user"
      />-->

      <Afiliado
        v-if="cards.includes('afiliado-pendients')"
        :user="user"
      />

      <Apps
        v-if="cards.includes('download-apps')"
        :user="user"
      />
    </template>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved, import/extensions
import LoadUserMixin from '@/mixins/LoadUserMixin.js'
// eslint-disable-next-line import/extensions, import/no-unresolved
import LoadProductsHotelsInfoDezMixin from '@/mixins/LoadProductsHotelsInfoDezMixin.js'
// eslint-disable-next-line import/extensions, import/no-unresolved, no-unused-vars
import LoadProductsHotelsInfoMixin from '@/mixins/LoadProductsHotelsInfoMixin.js'
import Contrates from './b2b/Contrates.vue'
import Sales from './b2b/Sales.vue'
import ChatBot from './b2b/ChatBot.vue'
import Apps from './b2b/Apps.vue'
import Markups from './b2b/Markups.vue'
import DataGeneral from './b2b/DataGeneral.vue'
import Notificaciones from './b2b/Notificaciones.vue'
import Grafana from './grafana/Grafana.vue'
import CintilloCreateUser from './b2b/cintillos/CintilloCreateUser.vue'
import CintilloCreateSeller from './b2b/cintillos/CintilloCreateSeller.vue'
import CintilloCreateAgente from './b2b/cintillos/CintilloCreateAgente.vue'
import CintilloCreateAdmin from './b2b/cintillos/CintilloCreateAdmin.vue'
import DeepL from './b2b/deepl/DeepL.vue'
import Afiliado from './b2b/afiliado/Afiliado.vue'

export default {
  components: {
    Contrates,
    Sales,
    ChatBot,
    Apps,
    CintilloCreateUser,
    CintilloCreateSeller,
    CintilloCreateAgente,
    CintilloCreateAdmin,
    Notificaciones,
    // eslint-disable-next-line vue/no-unused-components
    Grafana,
    Markups,
    DataGeneral,
    DeepL,
    Afiliado,
  },
  mixins: [
    LoadUserMixin,
    LoadProductsHotelsInfoDezMixin,

    // LoadProductsHotelsInfoMixin,
  ],
  data() {
    return {
      perfil: {},
      user: {},
      offline: false,
      isLoading: true,
      cards: localStorage.getItem('cards_auth'),
    }
  },
  computed: {
    ...mapState({
      isLoadingPage: state => state.app.isLoadingPage,
      chatBotRead: state => state.app.chatBotRead,
      listUser: state => state.app.listUser,
      showCardCreateUser: state => state.app.showCardCreateUser,
      showCardCreateSeller: state => state.app.showCardCreateSeller,
      showCardCreateAgent: state => state.app.showCardCreateAgent,
      showCardCreateUserAdmin: state => state.app.showCardCreateUserAdmin,
    }),
  },
  mounted() {
    this.perfil = this.$route.params.perfil
    this.cargaPerfil()

    this.$watch(
      () => this.$route.params,
      // eslint-disable-next-line no-unused-vars
      (toParams, previousParams) => {
        // store.state.app.cargandoMenu = true
        this.perfil = toParams.perfil

        // localStorage.setItem('perfil', toParams.perfil)
        // store.commit('getPerfilesAuth')
        // setTimeout(() => {
        //   store.state.app.cargandoMenu = false
        //   window.location.reload()
        // }, 800)
      },
    )
  },
  created() {
    this.profile()
  },
  methods: {
    ...mapMutations([
      'setIsLoadingPage',
      'setShowCardCreateUser',
      'setShowCardCreateSeller',
      'setShowCardCreateAgent',
      'setShowCardCreateUserAdmin',
    ]),
    cargaPerfil() {
      // console.log(store.state.app.userProfile)
      setTimeout(() => {
        this.setIsLoadingPage(false)
      }, 800)
    },
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.company_id) {
        if (this.user.company) {
          if (this.user.company.offline) {
            this.offline = this.user.company.offline
          }
        }
      }

      this.listUser.forEach(element => {
        if (element.id === this.user.id) {
          this.setShowCardCreateUser(element.create_user)
          this.setShowCardCreateSeller(element.create_seller)
          this.setShowCardCreateAgent(element.create_agent)
          this.setShowCardCreateUserAdmin(element.create_admin)
        }
      })

      this.isLoading = false

      /* this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
          if (this.user.company_id) {
            if (this.user.company) {
              if (this.user.company.offline) {
                this.offline = this.user.company.offline
              }
            }
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoading = false
        }) */
    },
  },
}
</script>
